import React from "react";
import Layout from "../../components/layout";
import Trado from "../../images/Trado.jpg";
import Trado2 from "../../images/trado2.jpg";
import SEO from "../../components/seo";

function ST() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Sondagem A Trado"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${Trado2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white m-4 md:ml-8 md:mt-24 uppercase base-title-golden">
          Sondagem à Trado (ST)
        </h1>
      </section>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 m-2 xl:m-12">
        <div>
          <h3>
            A Sondagem a Trado permite o simples reconhecimento do solo, método
            que consiste na coleta de amostras que permitam distinguir as
            camadas de subsolo e o tipo de solo. O trado é constituído por uma
            haste metálica acoplada à sua extremidade, e é operado manualmente
            para obter-se informações simples e iniciais, como por exemplo a
            verificação do nível d’água.
          </h3>
          <h3 className="my-6">
            Os operadores penetram o solo girando a barra horizontal integrada a
            haste vertical e a broca, é necessário retirar a broca após algumas
            rotações para retirar o material que fica acumulado.
          </h3>
          <h3>
            A amostragem normalmente é feita metro a metro, pois o material
            coletado passa por muitas mudanças durante o processo, as quais são
            observadas e anotadas.
          </h3>
          <h3 className="my-6">
            A Sondagem a Trado é muito utilizada para a definição do nível do
            lençol freático. O resultado do trado manual, sempre será de
            amostras deformadas, pois o solo não mantém as mesmas
            características físicas quando retirado do contato com a natureza.
          </h3>
        </div>
        <img src={Trado}/>
      </div>
    </Layout>
  );
}

export default ST;
